//@flow
import React, {
  Component
} from 'react';
import css from './signup.scss.css';

// import {
//   Modal,
//   Button,
//   FormGroup,
//   FormControl,
//   HelpBlock,
//   ControlLabel,
//   Radio,
//   InputGroup
// } from 'react-bootstrap';

import Modal from 'react-bootstrap/es/Modal'
import Button from 'react-bootstrap/es/Button'
import FormGroup from 'react-bootstrap/es/FormGroup'
import FormControl from 'react-bootstrap/es/FormControl'
import Checkbox from 'react-bootstrap/es/Checkbox'
import HelpBlock from 'react-bootstrap/es/HelpBlock'
import ControlLabel from 'react-bootstrap/es/ControlLabel'
import Radio from 'react-bootstrap/es/Radio'
import InputGroup from 'react-bootstrap/es/InputGroup'


import {
  Actions
} from './signup-actions'
import {
  validateEmail,
  validateName,
  validateMobile,
  validateSex,
  validateDate
} from './validate';
import DatePicker from 'react-datepicker';
import moment from 'moment';
// const moment = import('moment')

// import 'react-datepicker/dist/react-datepicker.min.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import invariant from 'invariant';

import 'matchmedia-polyfill';
// import MediaQuery from 'react-responsive';
require('./modernizr-date-input-detect');
type Props = {
  title: string,
  show: boolean,
  store: Object
}

class Modal2 extends Component<Props> {

  formIsValidated = false;
  useDateInput = false;

  componentWillMount() {
    //test to decide whether to use browser date input or custom datepicker
    if (window.Modernizr.inputtypes.date && window.matchMedia('only screen and (max-width: 768px)').matches) {
      //date input is available && we are probably on mobile
      this.useDateInput = true
    }
  }

  handleSubmit(e: SyntheticInputEvent<*>) {
    e.preventDefault();
    e.stopPropagation();
    if (this.formIsValidated) Actions.submitM2();
  }

  handleChange(o: Object) {
    let vals = {};
    Object.entries(o).forEach((e) => {
      switch (e[0]) {
        case 'email':
          vals['emailIsValidated'] = validateEmail(String(e[1]))
          break;
        case 'mobile':
          vals['mobileIsValidated'] = validateMobile(String(e[1]))
          break;
        case 'firstname':
          vals['firstnameIsValidated'] = validateName(String(e[1]))
          break;
        case 'lastname':
          vals['lastnameIsValidated'] = validateName(String(e[1]))
          break;
        case 'sex':
          vals['sexIsValidated'] = validateSex(String(e[1]))
          break;
        case 'dob':
          let date = e[1];
          if (!date) break;
          invariant(date && (typeof date === "object" || typeof date === 'string'), "not a valid date object or string");
          vals['dobIsValidated'] = validateDate(date);
          if (typeof date === 'string') {
            date = this.useDateInput ? moment(date, "YYYY-MM-DD") : moment(date, "DD-MM-YY");
          }
          if (vals['dobIsValidated'] === 'success') {
            o.dob = date;
          } else {
            o.dob = null
          }

          break;
        default:
      }
    })
    Object.assign(o, vals)
    Actions.setData(o)
  }

  setFormIsValidated() {
    let vs = [];
    vs.push(this.props.store.get("emailIsValidated"));
    vs.push(this.props.store.get("firstnameIsValidated"));
    vs.push(this.props.store.get("lastnameIsValidated"));
    // vs.push(this.props.store.get("mobileIsValidated")); // disabled as optional
    vs.push(this.props.store.get("dobIsValidated"));
    vs.push(this.props.store.get("sexIsValidated"));
    vs.push(this.props.store.get("confirmIntent") ? true : null);

    if (vs.includes('error') || vs.includes('warning') || vs.includes(null) || vs.includes(undefined)) {
      this.formIsValidated = false;
    } else {
      this.formIsValidated = true;
    }
  }

  getValidationState(key: string): boolean {
    let lookup = key + "IsValidated";
    let v = this.props.store.get(lookup);
    return v;
  }



  render() {
    this.setFormIsValidated();


    let openToDate = this.props.store.get("dob") ? this.props.store.get("dob") : moment("1995-01-01");
    let date = this.props.store.get("dob");
    if (date && this.useDateInput) date = date.format("YYYY-MM-DD");
    if (!date) date = null;
    let dateInputNative = <FormControl
      name="dob"
      type="date"
      value={date}
      placeholder="dob"
      onChange={(e: SyntheticInputEvent<*>) => this.handleChange({ dob: e.target.value })}
    />
    let dateInputDatePicker =
      <DatePicker
        placeholderText="click to select date"
        dateFormat="DD/MM/YY"
        selected={date}
        onChange={(date: Object) => this.handleChange({ dob: date })}
        onBlur={(e: SyntheticInputEvent<*>) => this.handleChange({ dob: e.target.value })}
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        openToDate={openToDate}
        popperPlacement="right"
        customInput={<input style={{ color: "#555", borderRadius: "4px" }} type="text" />}
      />

    let dateInput = this.useDateInput ? dateInputNative : dateInputDatePicker;

    return <div>
      <Modal show={this.props.show} className={css.signupModal}>
        <Modal.Header>
          <Modal.Title>{this.props.title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <form>
            {/* firstname */}
            <FormGroup
              controlId="firstname"
              validationState={this.getValidationState("firstname")}
              onSubmit={(e: SyntheticInputEvent<*>) => this.handleSubmit(e)}
            >
              <ControlLabel>First Name *</ControlLabel>
              <FormControl
                autoComplete='given-name'
                name="firstname"
                type="text"
                value={this.props.store.get("firstname")}
                placeholder="firstname"
                onChange={(e: SyntheticInputEvent<*>) => this.handleChange({ firstname: e.target.value })}
              />
              <FormControl.Feedback />
              <HelpBlock></HelpBlock>
            </FormGroup>

            {/* lastname */}
            <FormGroup
              controlId="lastname"
              validationState={this.getValidationState("lastname")}
              onSubmit={(e: SyntheticInputEvent<*>) => this.handleSubmit(e)}
            >
              <ControlLabel>Last Name *</ControlLabel>
              <FormControl
                autoComplete='family-name'
                name="lastname"
                type="text"
                value={this.props.store.get("lastname")}
                placeholder="lastname"
                onChange={(e: SyntheticInputEvent<*>) => this.handleChange({ lastname: e.target.value })}
              />
              <FormControl.Feedback />
              <HelpBlock></HelpBlock>
            </FormGroup>

            {/* email */}
            <FormGroup
              controlId="email"
              validationState={this.getValidationState("email")}
              onSubmit={(e: SyntheticInputEvent<*>) => this.handleSubmit(e)}
            >
              <ControlLabel>Email *</ControlLabel>
              <FormControl
                autoComplete='email'
                name="email"
                type="text"
                value={this.props.store.get("email")}
                placeholder="email"
                onChange={(e: SyntheticInputEvent<*>) => this.handleChange({ email: e.target.value })}
              />
              <FormControl.Feedback />
              <HelpBlock></HelpBlock>
            </FormGroup>


            {/* dob */}
            <FormGroup
              controlId="dob"
              validationState={this.getValidationState("dob")}
              onSubmit={(e: SyntheticInputEvent<*>) => this.handleSubmit(e)}
            >
              <ControlLabel>Date of birth *</ControlLabel>

              {dateInput}

              <FormControl.Feedback />
              <HelpBlock></HelpBlock>
            </FormGroup>


            {/* sex */}

            <FormGroup
              controlId="sex"
              validationState={this.getValidationState("sex")}
              onSubmit={(e: SyntheticInputEvent<*>) => this.handleSubmit(e)}
              onChange={(e: SyntheticInputEvent<*>) => this.handleChange({ sex: e.target.value })}
            >

              <Radio
                name="sexGroup"
                inline
                value="M"
              >Male</Radio>
              <Radio
                name="sexGroup"
                inline
                value="F"
              >Female</Radio>
              <FormControl.Feedback />
              <HelpBlock></HelpBlock>
            </FormGroup>


            <FormGroup
              controlId="confirmIntent"
              validationState={this.props.store.get("confirmIntent") ? "success" : null}
              onSubmit={(e: SyntheticInputEvent<*>) => this.handleSubmit(e)}
            >

              <Checkbox
                name="confirmIntent"
                checked={this.props.store.get("confirmIntent")}
                onChange={(e: SyntheticInputEvent<*>) => this.handleChange({ confirmIntent: e.target.checked })}
              >I agree that Reminisce Festival may contact me by email with news and offers regarding the festival & related events.</Checkbox>


              <FormControl.Feedback />
            </FormGroup>


          </form>
          <div className={css.signupSmallprint}>We don't share your details with anyone else. You can remove yourself from the mailing list at any time. For more information about our data policies see <a href="/data">here.</a></div>

        </Modal.Body>
        <Modal.Footer>
          {/* <div className="signup-smallprint">
            We don't sell your data or let anyone else see it - we'll only use it for letting you know about our stuff.
          </div> */}
          <Button onClick={Actions.cancelM}>Close</Button>
          <Button type='submit' disabled={!this.formIsValidated} bsStyle="primary" onClick={(e: SyntheticInputEvent<*>) => this.handleSubmit(e)}>Send</Button>
        </Modal.Footer>
      </Modal>
    </div>
  }
}

export default Modal2;
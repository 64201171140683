//@flow
import React from "react";
import css from "./chatroom.scss.css";
import moment from "moment";

type Props = { onSubmit: Function, clientID: string, isAdmin: boolean };
type State = { message: string, nickname: string, settingNickname: boolean };

class MessageComposer extends React.Component<Props, State> {
  constructor(props: Object) {
    super(props);
    this.state = { message: "", nickname: localStorage.getItem("@nickname") || "user_" + this.props.clientID.substr(0, 6), settingNickname: false };
  }
  //testing
  _makeGibberish(length: number) {
    var result = "";
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnop qrstu vwxy z0123 456789";
    // var characters = "😎😎😎😎😎😎😎";

    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  _tick() {
    const vars = {
      Body: this._makeGibberish(Math.floor(Math.random() * 120)),
      ClientDeviceID: "testbot",
      Nickname: "Test Bot [" + this.props.clientID.substr(0, 4) + "]",
      Timestamp: moment().unix(),
    };
    this.props.onSubmit(vars);
    setTimeout(() => this._tick(), Math.random() * 3000);
  }
  componentDidMount() {
    // this._tick();
  }
  //end testing

  _onSubmit(e?: SyntheticInputEvent<*>) {
    e && e.stopPropagation();
    e && e.preventDefault();
    const vars = {
      Body: this.state.message,
      ClientDeviceID: this.props.isAdmin ? "admin" : this.props.clientID,
      Nickname: this.state.nickname || this.props.clientID.substr(0, 8),
      Timestamp: moment().unix(),
    };
    this.props.onSubmit(vars);
    this.setState({ message: "" });
    console.log(vars);
  }

  _onKeyDown(event: SyntheticKeyboardEvent<*>) {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      this._onSubmit();
    }
  }

  _setNickname(e: SyntheticInputEvent<*> | SyntheticKeyboardEvent<*>, button: boolean) {
    // e.preventDefault();
    // e.stopPropagation();
    if (e.key === "Enter" || button) {
      localStorage.setItem("@nickname", this.state.nickname);
      localStorage.setItem("@nicknameupdated", moment().unix());
      this.setState({ settingNickname: false });
    }
  }

  render() {
    // console.log(this.state);
    if (!this.state.settingNickname)
      return (
        <div className={css.composer}>
          <div className={css.header}>
            <h5>Send a message{this.props.isAdmin ? " from ADMIN" : null}</h5>
            <div onClick={() => this.setState({ settingNickname: true })}>
              {this.state.nickname}&nbsp;<ion-icon name="create-outline"></ion-icon>
            </div>
          </div>
          <input
            placeholder="message"
            className={css.message}
            type="text"
            maxLength={180}
            value={this.state.message}
            onChange={(e) => this.setState({ message: e.target.value })}
            onKeyDown={(e) => this._onKeyDown(e)}
            autoFocus={false}
          />
          <div className={css.button} onClick={(e) => this._onSubmit(e)}>
            SEND&nbsp;<ion-icon name="arrow-forward-circle-sharp"></ion-icon>
          </div>
        </div>
      );

    const now = moment().unix();
    const lastUpdated = Number(localStorage.getItem("@nicknameupdated"));
    console.log(lastUpdated);
    if (now - lastUpdated > 20 * 60)
      return (
        <div className={css.composer}>
          <h5>Set a nickname (Max 12 character)</h5>
          <input
            className={css.nickname}
            type="text"
            placeholder="nickname"
            value={this.state.nickname}
            maxLength={12}
            onChange={(e) => this.setState({ nickname: e.target.value })}
            onKeyDown={(e) => this._setNickname(e, false)}
          />
          <h6>You can only do this once</h6>

          <div className={css.button} onClick={(e) => this._setNickname(e, true)}>
            SAVE&nbsp;<ion-icon name="arrow-forward-circle-sharp"></ion-icon>
          </div>
          {/* <div className={css.button} onClick={(e) => this.setState({ settingNickname: false })}>
            CANCEL&nbsp;<ion-icon name="arrow-forward-circle-sharp"></ion-icon>
          </div> */}
        </div>
      );

    return (
      <div className={css.composer}>
        <p>You cannot change your nickname just yet. Please check back later</p>
        <div className={css.button} onClick={(e) => this.setState({ settingNickname: false })}>
          OK&nbsp;<ion-icon name="arrow-forward-circle-sharp"></ion-icon>
        </div>
      </div>
    );
  }
}

export default MessageComposer;

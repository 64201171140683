//@flow
import React from 'react';
import css from './navbar.scss.css';
// import {
//   NavLink
// } from 'react-router-dom'
import {
  Actions
} from './actions';
import MediaQuery from 'react-responsive';

type Props = {
  store: Object,
  items: Object
}


class MainMenu extends React.Component<Props> {

  handleClick(e: SyntheticInputEvent<*>, url: string | mixed) {
    Actions.setShowMenu(false);
    if (url && typeof url === 'string')
      window.location.href = url;
  }

  handleToggleMore(e: SyntheticInputEvent<*>) {
    if (this.props.store.get('showMenu') === true) {
      Actions.setShowMenu(false)
    } else {
      Actions.setShowMenu(true)
    }
  }
  render() {
    let moreButton = <div className={css.link} onClick={(e) => this.handleToggleMore(e)} >
      MORE&nbsp;<i className="fa fa-caret-down"></i>
    </div>;
    let menuButton = <div className={css.link} onClick={(e) => this.handleToggleMore(e)} >
      Menu&nbsp;<i className="fa fa-bars"></i>
    </div>;



    let path = window.location.pathname;
    // 
    if (!path || path === "/") path = "/home";

    const regex = RegExp(`^${path}$`);
    let items = [];
    Object.entries(this.props.items).forEach(([k, v]) => {

      const selected = typeof v === 'string' && regex.test(v) ? css.selected : null;

      items.push(<div key={k} onClick={(e) => this.handleClick(e, v)} to={v} className={[css.link, selected].join(" ")} >{k}</div>);

      // items.push(<div key={`${k}div`} className={css.verticalDivider}>&nbsp;</div>);
    })
    // items.pop();
    return <div className={css.mainMenu}>
      <MediaQuery minWidth={767}>
        {/* {items} */}
      </MediaQuery>
      <MediaQuery minWidth={767}>
        {(matches) => {
          if (matches) {
            return moreButton;
          } else {
            return menuButton;
          }
        }}
      </MediaQuery>

    </div>
  }
}
export default MainMenu;
//@flow
import React, {
  Component
} from 'react';

// import {
//   Modal,
//   Button
// } from 'react-bootstrap';
import Modal from 'react-bootstrap/es/Modal'
import Button from 'react-bootstrap/es/Button'
import css from './signup.scss.css';
import {
  Actions
} from './signup-actions'
import Loading from './Loading'
type Props = {
  // title: string,
  show: boolean,
  store: Object
}

const loadingTitle = "Sending...";
const successTitle = "Success!";
const errorTitle = "Oops!";

const successBody = <span>You should recieve a confirmation email in a few minutes.<br /> Don't forget to check your junk folder if you can't find it.</span>;
const loadingBody = <Loading />;
const errorBody = "Sorry, seems there was a problem";

class Modal3 extends Component<Props> {

  render() {

    let status = this.props.store.get("apiStatus");
    let title = "";
    let body = "";

    if (status === 'waiting') {
      title = loadingTitle;
      body = loadingBody;
    }
    if (status === 'success') {
      title = successTitle;
      body = successBody;
    }
    if (status === 'error') {
      title = errorTitle;
      body = errorBody;
    }
    return <div>
      <Modal show={this.props.show} className={css.signupModal}>
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {body}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={Actions.cancelM}>Close</Button>
        </Modal.Footer>
      </Modal>
    </div>
  }
}

export default Modal3;
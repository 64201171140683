//@flow
import React from 'react';

import css from './navbar.scss.css';
import {
  Container
} from 'flux/utils';
import Store from './store';
import invariant from 'invariant';
import MainMenu from './mainmenu';
import SubMenu from './submenu';
import MediaQuery from 'react-responsive';

// require('matchmedia-polyfill');

class NavBar extends React.Component<*,> {

  static getStores() {
    return [Store];
  }

  static calculateState(prevState) {
    return {
      store: Store.getState(),
    };
  }



  render() {
    let store = this.state ? this.state.store : null;
    invariant(store, "No store!");

    return <div className={css.container}>
      <MainMenu items={this.props.mainMenuItems} store={store} {...this.props} />
      <SubMenu items={{ ...this.props.mainMenuItems, ...this.props.subMenuItems }} store={store} />
    </div>
  }
}
export default Container.create(NavBar);
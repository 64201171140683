//@flow
import React, {
  Component
} from 'react';

import Modal1 from './modal1';
import Modal2 from './modal2';
import Modal3 from './modal3';

// brandid: '1110000000000005',
//   auth: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOiIyODE0NzQ5ODAwMDkwMDAzIn0.-XRKRlHhPj1lHWBt4SH3LxMVJhwaODblNdNJCkDrAjA",
//   responseBody: "You've successfully submitted your email to Back To The Anthems to receive news and updates about future events<br><br>",
//   responseSubject: "",
//   bugDelayTime: 3000,
//   alwaysSilent: false,

type Props = {
  brandId: string,
  auth: string,
  responseBody ? : string,
  responseSubject ? : string,
  timeout: number,
  alwaysSilent: boolean,
  firstTitle: string,
  secondTitle: string,
  store: Object,
  showNow: boolean
}


class Signup extends Component < Props > {

  static defaultProps = {
    timeout: 0,
    alwaysSilent: false,
    firstTitle: "Enter your email!",
    secondTitle: "Enter more details",
    store: {}
  }

  // showNow = false;
  // componentWillUpdate() {
  //   //override show modal2 here for click to opem
  //   console.log('will')
  //   this.showNow = this.props.showNow ? true : this.props.store.get('showModal2');
  //   console.log(this.showNow)
  // }
  // shouldComponentUpdate() {
  //   console.log('should')
  //   this.showNow = this.props.showNow ? true : this.props.store.get('showModal2');
  //   console.log(this.showNow)
  //   return this.showNow
  // }

  render() {
    return <div>
      <Modal1 title={this.props.firstTitle} show={this.props.store.get('showModal1')} store={this.props.store}/>
      <Modal2 title={this.props.secondTitle} show={this.props.store.get('showModal2')} store={this.props.store}/>
      <Modal3 show={this.props.store.get('showModal3')} store={this.props.store}/>
    </div>

  }
}

export default Signup;
// @flow
import fetch from 'isomorphic-fetch';
import Store from './signup-store';
import {
  Actions
} from './signup-actions';

import {
  Cookies
} from 'react-cookie';

// Class for wrapping API calls
class API {

  //apiVersion corresposnds to the version to call: '2' is the current live api; '2b' is the beta update;
  // both currently work on the production database
  // (this probably needs changing!)
  static apiVersion = 'dev';

  // the base url of the API
  static apiUrl = "https://api.indemand.digital";

  // the key required to be allowed to call the API
  // this is set on the AWS API Gateway console
  static apiKey = "0kmNYhxHPP9YCscUNiuGv53oyoOlfDPmaYjL1BfI";
  static iddApiAuth = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOiIyODE0NzQ5ODAwMDkwMDAzIn0.-XRKRlHhPj1lHWBt4SH3LxMVJhwaODblNdNJCkDrAjA";

  static post(): void {
    Actions.setAPIStatus("waiting")
    let store = Store.getState();
    const silent = store.get('hasSentEmail');
    //build dataobject
    let payload = {};
    let fields = ['email', 'firstname', 'lastname', 'dob', 'sex', 'mobile'];
    payload.silent = silent;
    payload.auth = API.iddApiAuth;

    fields.forEach((o) => {
      // add fields to payload
      if (store.get(o)) payload[o] = store.get(o);

      // format special cases
      if (o === 'dob' && store.get(o) && store.get(o).hasOwnProperty("_isAMomentObject") && store.get(o)._isAMomentObject === true)
        payload.dob = store.get(o).format('YYYY-MM-DD');;
      if (o === 'sex' && payload.sex)
        payload['gender'] = payload.sex;
      if (o === 'mobile' && payload.mobile)
        payload['phone_mobile'] = payload.mobile;
    })

    payload.addBrandLike = store.get('brandId');
    if (store.get('responseSubject')) payload.responseSubject = store.get('responseSubject');
    if (store.get('responseBody')) payload.responseBody = store.get('responseBody');
    if (store.get('auth')) payload.auth = store.get('auth');

    // build the URL
    let url = "https://api.indemand.digital/v2/dir/person";

    // console.log("Requesting: " + url);
    console.log(payload)
    // start setting the headers
    let headers = new Headers();

    // This header required for AWS API to allow a call
    headers.append("x-api-key", API.apiKey);
    headers.append("Content-Type", "application/json");

    let myInit = {
      method: 'POST',
      headers: headers,
      mode: 'cors',
      cache: 'default',
      body: JSON.stringify(payload)
    };

    let request = new Request(url, myInit);


    fetch(request).then(function (response) {
      if (response.ok) {
        return response.blob();
      }
      // console.log(response)
      throw new Error('Network response was not ok.');
    }).then(function (myBlob) {
      //returned data here
      Actions.setAPIStatus("success")
      if (silent !== true) {
        let date = new Date();
        date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 1000));
        let expires = date;

        let cookies = new Cookies();
        cookies.set('c87ndwjdj3edn3ued7', "03dnc63rhdjh3444dx", {
          path: '/',
          expires: expires
        });

      }
    }).catch(function (error) {
      Actions.setAPIStatus("error")
      console.log('There has been a problem: ' + error.message);
    });

  }

}

export default API;
//@flow
import React from 'react'
import css from './cookie-accept.css';

import {
  Cookies
} from 'react-cookie';

type State = {
  show: boolean;
}

class CookieAccept extends React.Component < * , State > {
  state = {
    show: true
  }

  componentWillMount() {
    const cookies = new Cookies();
    if (cookies.get('ca')) {
      this.setState({
        show: false
      })
    }
  }

  setAcceptCookie() {
    const date = new Date();
    date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 1000));
    // const expires = date;

    const cookies = new Cookies();
    cookies.set('ca', true, {
      path: '/',
      expires: date
    });
  }

  handleDismiss() {
    this.setState({
      show: false
    })
  }

  handleAccept() {
    this.setAcceptCookie();
    this.setState({
      show: false
    })
  }

  render() {
    const displayclass = this.state.show ? css.show : css.hide;
    return <div className={[css.cookieAcceptContainer,displayclass].join(" ")}>
      <div className={css.message}><span>This website uses cookies -&nbsp;<a href='/data'>details</a></span></div>
      <div className={css.buttons}>
        <div className={css.button} onClick={()=>this.handleDismiss()}>Dismiss</div>
        <div className={css.button} onClick={()=>this.handleAccept()}>Accept</div>
      </div></div>
  }
}
export default CookieAccept;
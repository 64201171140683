//@flow
import React from "react";
import css from "./chatroom.scss.css";
import type { Message } from "./index";
// import moment from "moment";
import MessageRow from "./message";

const speed = 4;
const userScrollTimeout = 4;
type Props = { messages: Array<Message>, clientID: string, chatBotName: string };
type State = { userHasScrolled: boolean, autoscroll: boolean };

class MessageList extends React.Component<Props, State> {
  state = { userHasScrolled: false, autoscroll: true };
  userScrolledTimeout: *;
  isScrolling = false;
  listRef: *;
  constructor(props: Object) {
    super(props);
    this.listRef = React.createRef();
  }

  _userScrollDetected() {
    console.log("wheel");
    this.setState({ userHasScrolled: true });
    clearTimeout(this.userScrolledTimeout);
    this.userScrolledTimeout = setTimeout(() => {
      console.log("user scroll timeout");
      this.setState({ userHasScrolled: false });
    }, userScrollTimeout * 1000);
  }
  componentDidMount() {
    this.listRef.current && this.listRef.current.addEventListener("wheel", () => this._userScrollDetected());
    this.listRef.current && this.listRef.current.addEventListener("touchstart", () => this._userScrollDetected());
  }

  _scrollTo(element: Object, to: number, duration: number) {
    if (duration <= 0 || this.state.userHasScrolled) {
      this.isScrolling = false;
      return;
    }
    // console.log("scrollto", to, element.scrollTop);

    this.isScrolling = true;
    var difference = to - element.scrollTop;
    var perTick = (difference / duration) * speed;

    setTimeout(() => {
      element.scrollTop = element.scrollTop + perTick;
      if (element.scrollTop === to) {
        this.isScrolling = false;
        return;
      }
      this._scrollTo(element, to, duration - speed);
    }, speed);
  }

  _doScroll() {
    const log = this.listRef.current;
    if (!log || this.state.autoscroll === false) return;

    // console.log("LIST", log.scrollHeight, log.clientHeight);
    !this.state.userHasScrolled && this.isScrolling === false && this._scrollTo(log, log.scrollHeight - log.clientHeight, 100);

    // }
  }

  componentDidUpdate() {
    this._doScroll();
  }

  render() {
    const messages = this.props.messages.map((m, i) => {
      // const cl = m.Nickname === this.props.chatBotName ? css.messageFromBot : m.ClientDeviceID === this.props.clientID ? css.messageFromMe : null;

      return <MessageRow key={i} message={m} isBot={m.Nickname === this.props.chatBotName} isMe={m.ClientDeviceID === this.props.clientID} isAdmin={m.ClientDeviceID === "admin"} />;
    });
    //

    return (
      <div className={css.listContainer}>
        <div className={css.list} ref={this.listRef}>
          {messages}
        </div>
        {/* <div className={css.fader}></div> */}
        <div className={css.autoscrollButton} onClick={() => this.setState({ autoscroll: !this.state.autoscroll })}>
          AUTO SCROLL {this.state.autoscroll === true ? "ON" : "OFF"}
        </div>
      </div>
    );
  }
}

export default MessageList;

//@flow
import React from "react";
import css from "./submenu.scss.css";

// import { Link } from "react-router-dom";

import { Actions } from "./actions";
// import ImageProvider from "../../image-provider-js";

class SubMenuMobile extends React.Component<*> {
  handleClick(e: SyntheticInputEvent<*>, url: string | mixed) {
    Actions.setShowMenu(false);
    if (url && typeof url === "string") setTimeout(() => (window.location.href = url), 100);
  }

  scrollY: number = 0;

  fixBody(to: boolean) {
    const body = document.getElementsByTagName("BODY");

    if (to) {
      this.scrollY = window.scrollY;
      body[0].classList.add("fixBody");
      const ss = this.scrollY * -1;
      body[0].style.top = ss + "px";
    } else {
      body[0].classList.remove("fixBody");
      body[0].style.top = "unset";
      window.scrollTo(0, this.scrollY);
    }
  }

  render() {
    this.fixBody(this.props.store.get("showMenu"));

    let items = [];

    Object.entries(this.props.items).forEach(([k, v]) => {
      if (typeof v === "string") {
        items.push(
          <div key={k} onClick={(e) => this.handleClick(e, v)} to={v} className={css.link}>
            >&nbsp;{k}
          </div>
        );
      } else if (typeof v === "function") {
        items.push(
          <div key={k} onClick={v} className={css.link}>
            >&nbsp;{k}
          </div>
        );
      }
    });

    let position = this.props.store.get("showMenu") ? css.show : css.hide;
    let fade = this.props.store.get("showMenu") ? css.in : css.out;

    return (
      <div>
        <div className={[css.shadow, fade].join(" ")} onClick={(e) => this.handleClick(e)} />
        <div className={[css.container, position].join(" ")} onClick={(e) => this.handleClick(e)}>
          <div className={css.itemswrapper}>{items}</div>
        </div>
      </div>
    );
  }
}

export default SubMenuMobile;

//@flow
import React from "react";
import MessageComposer from "./composer";
import MessageList from "./messagelist";
import { v4 as uuidv4 } from "uuid";
import css from "./chatroom.scss.css";
import moment from "moment";
//

export type Message = { Body: string, ClientDeviceID: string, Nickname: string, Timestamp: string };

const chatbotName = "ChatBot";

type Props = { admin: boolean };
type State = { messages: Array<Message>, webSocketSupported: boolean, clientID: string, chatConnected: boolean };

const chatroomNode = "wss://chatroom-node.service.indemand.digital/ws";
// const chatroomNode = "ws://192.168.0.13:9005/ws";

class Chatroom extends React.Component<Props, State> {
  constructor(props: Object) {
    super(props);
    const supported = window["WebSocket"] !== undefined;
    let clientID = localStorage.getItem("@clientid");
    if (!clientID) {
      clientID = uuidv4();
      localStorage.setItem("@clientid", clientID);
    }

    // const t1: Message = { Body: "Helllo this is great", ClientDeviceID: "123", Nickname: "Dave Biscuit", Timestamp: "0" };
    // const t2: Message = { Body: "Helllo this is great", ClientDeviceID: "123", Nickname: "Dave Biscuit", Timestamp: "0" };
    // const t3: Message = { Body: "Helllo this is great", ClientDeviceID: clientID, Nickname: "me", Timestamp: "0" };
    // const t4: Message = { Body: "Helllo this is great", ClientDeviceID: "123", Nickname: "Dave Biscuit", Timestamp: "0" };
    // const t5: Message = { Body: "Helllo this is great", ClientDeviceID: "123", Nickname: "Dave Biscuit", Timestamp: "0" };
    // const tests = [t1, t2, t3, t4, t5];

    this.state = { webSocketSupported: supported, messages: [], clientID: clientID, chatConnected: false };
  }

  // conn: *;//using window

  _appendLog(item: Message) {
    let { messages } = this.state;
    // if (messages.length > 10) {
    //   messages = messages.slice(messages.length - 10, messages.length);
    // }
    this.setState({ messages: [...messages, item] });
  }

  _connectChat() {
    if (this.state.webSocketSupported) {
      if (window.chatSocket !== null) {
        window.chatSocket && window.chatSocket.close();
        window.chatSocket = null;
      }
      window.chatSocket = new WebSocket(chatroomNode);

      window.chatSocket.onopen = () => {
        console.log("WS OPEN")
        const m: Message = { Body: "Chat Connected", ClientDeviceID: this.state.clientID, Nickname: chatbotName, Timestamp: moment().unix() };
        this._appendLog(m);
        this.setState({ chatConnected: true });
      };
      window.chatSocket.onclose = () => {
        console.log("WS CLOSE")
        const m: Message = { Body: "LiveChat Disconnected", ClientDeviceID: this.state.clientID, Nickname: chatbotName, Timestamp: moment().unix() };
        this._appendLog(m);
        this.setState({ chatConnected: false });
        window.chatSocket = null;
        setTimeout(() => this._connectChat(), 5000);
      };
      window.chatSocket.onerror = (e) => {
        console.error("WS ERROR",e)
        // const m: Message = { Body: "LiveChat Error", ClientDeviceID: this.state.clientID, Nickname: chatbotName, Timestamp: moment().unix() };
        // this._appendLog(m);
        // this.setState({ chatConnected: false });
        // window.chatSocket = null;
        // setTimeout(() => this._connectChat(), 5000);
      };

      window.chatSocket.onmessage = (evt: Object) => {
        // console.log(evt);
        const data: string = evt.data;
        var messages = data.split("\n");
        for (var i = 0; i < messages.length; i++) {
          const m = JSON.parse(messages[i]);
          // if (m.ClientDeviceID !== this.state.clientID) this._appendLog(m);
          this._appendLog(m);
        }
      };
    } else {
      const m: Message = { Body: "Your browser does not support Chat", ClientDeviceID: this.state.clientID, Nickname: chatbotName, Timestamp: moment().unix() };
      this._appendLog(m);
    }
  }
  componentWillUnmount() {
    window.chatSocket && window.chatSocket.close();
    window.chatSocket = null;
  }
  componentDidMount() {
    this._connectChat();
  }

  onSubmit(payload: Message) {
    payload.Timestamp = payload.Timestamp.toString();
    // payload.ClientDeviceID = this.state.clientID;
    // console.log(payload);
    if (!window.chatSocket) {
      return;
    }
    if (!payload.Body) {
      return;
    }
    if (this.state.chatConnected) window.chatSocket.send(JSON.stringify(payload));
  }

  componentDidCatch() {
    console.error("catch error");
  }
  render() {
    return (
      <div className={css.container}>
        <MessageList messages={this.state.messages} clientID={this.state.clientID} chatBotName={chatbotName} />
        <MessageComposer onSubmit={(payload) => this.onSubmit(payload)} clientID={this.state.clientID} isAdmin={this.props.admin} />
        <div className={css.sugarbeat}>
          <a href="https://sugarbeat.tv">LiveChat by SugarBeat.TV</a>
        </div>
      </div>
    );
  }
}

export default Chatroom;

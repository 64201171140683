//@flow

import {
  OrderedMap,
} from 'immutable/dist/immutable.min';


import {
  ReduceStore
} from 'flux/utils';

import Dispatcher from '../../../Dispatcher';

import {
  ActionTypes
} from './signup-actions';

class Store extends ReduceStore<Object> {
  constructor() {
    super(Dispatcher);
  }

  getInitialState(): Object {
    return OrderedMap({
      showModal1: false,
      showModal2: false,
      showModal3: false,
      email: "",
      firstname: "",
      lastname: "",
      dob: null,
      sex: "",
      mobile: "",
      apiStatus: 'waiting',
      confirmIntent: false,
      hasSentEmail: false
    });
  }

  reduce(state: Object, action: Object): Object {
    switch (action.type) {
      case ActionTypes.TRIGGER_M1:
        return state.withMutations(map => {
          map.set("showModal1", true).set('showModal2', false).set('showModal3', false).set('hasSentEmail', false)
        })

      case ActionTypes.TRIGGER_M2:
        return state.withMutations(map => {
          map.set("showModal1", false).set('showModal2', true).set('showModal3', false).set('hasSentEmail', false)
        })
      case ActionTypes.TRIGGER_M3:
        return state.withMutations(map => {
          map.set("showModal1", false).set('showModal2', false).set('showModal3', true)
        })
      case ActionTypes.SUBMIT_M1:
        return state.withMutations(map => {
          map.set("showModal1", false)
            .set('showModal2', true)
            .set('showModal3', false).set('hasSentEmail', true)

        })
      case ActionTypes.SUBMIT_M2:
        return state.withMutations(map => {
          map.set("showModal1", false)
            .set('showModal2', false)
            .set('showModal3', true).set('hasSentEmail', true)
        })
      case ActionTypes.CANCEL_M:
        return state.withMutations(map => {
          map.set("showModal1", false)
            .set('showModal2', false)
            .set('showModal3', false)
        })
      case ActionTypes.SET_DATA:
        return state.merge(action.data)
      case ActionTypes.SET_BRAND_DATA:
        return state.withMutations(map => {
          map.set("brandId", action.brandId)
            .set('responseSubject', action.responseSubject)
            .set('responseBody', action.responseBody)
        })
      case ActionTypes.SET_API_STATUS:
        return state.set('apiStatus', action.status)
      case ActionTypes.SET_CONFIRM_INTENT:
        return state.set('confirmIntent', action.data)
      default:
        return state;
    }
  }
}

export default new Store();
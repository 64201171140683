//@flow

export const validateEmail = (value: string) => {
  let ret = 'error';
  if (value.length <= 5)
    return null;
  let emailReg = /^([\w-.]+@([\w-]+.)+[\w-]{2,6})?$/;
  ret = (emailReg.test(value)) ? 'success' : 'warning';
  return ret;
}

export const validateName = (value: string) => {
  let ret = 'success';
  if (value.length <= 0)
    return null;
  if (value.length === 1)
    return "warning";
  return ret;
}

export const validateMobile = (value: string) => {
  //for no number submitted
  if (!value) return null;

  let n = value.replace(/\s/g, '');
  if (value[0] === '0') n = n.substr(1)
  if (n.length === 10) return 'success'
  return 'error';
}

export const validateDate = (date: Object | string) => {
  let dateformat1 = /^(0?[1-9]|[12][0-9]|3[01])[/-](0?[1-9]|1[012])[/-](19|20)?\d{2}$/;
  let dateformat2 = /^(19|20)?\d{2}[/-](0?[1-9]|1[012])[/-](0?[1-9]|[12][0-9]|3[01])$/;


  let dd = -1;
  let mm = -1;
  let yy = -1;
  if (typeof date === "string" && date) {
    if (date.match(dateformat1)) {
      let opera1 = date.split('/');
      let opera2 = date.split('-');
      let lopera1 = opera1.length;
      let lopera2 = opera2.length;
      let pdate = [];
      if (lopera1 > 1) {
        pdate = date.split('/');
      } else if (lopera2 > 1) {
        pdate = date.split('-');
      }
      dd = Number.parseInt(pdate[0], 10);
      mm = Number.parseInt(pdate[1], 10);
      yy = Number.parseInt(pdate[2], 10);
    } else if (date.match(dateformat2)) {
      let opera1 = date.split('/');
      let opera2 = date.split('-');
      let lopera1 = opera1.length;
      let lopera2 = opera2.length;
      let pdate = [];
      if (lopera1 > 1) {
        pdate = date.split('/');
      } else if (lopera2 > 1) {
        pdate = date.split('-');
      }
      dd = Number.parseInt(pdate[2], 10);
      mm = Number.parseInt(pdate[1], 10);
      yy = Number.parseInt(pdate[0], 10);
    } else {
      return 'error';
    }
  } else if (typeof date === "object" && date.hasOwnProperty("_isAMomentObject") && date._isAMomentObject === true) {
    if (!date.isValid()) return "error";

    dd = Number.parseInt(date.date(), 10);
    mm = Number.parseInt(date.month(), 10) + 1;
    yy = Number.parseInt(date.year(), 10);
  } else {

    return "error";
  }

  // Create list of days of a month [assume there is no leap year by default]
  let ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  if (mm === 1 || mm > 2) {
    if (dd > ListofDays[mm - 1]) {
      return "error";
    }
  }
  if (mm === 2) {
    let lyear = false;
    if ((!(yy % 4) && yy % 100) || !(yy % 400)) {
      lyear = true;
    }
    if ((lyear === false) && (dd >= 29)) {
      return "error";
    }
    if ((lyear === true) && (dd > 29)) {
      return "error";
    }
  }
  return "success"
}

export const validateSex = (value: string) => {
  let ret = 'success';
  if (value === 'M' || value === 'F')
    return ret;
  return "error"
}

exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".submenu-scss__snes___2zZgw{font-family:\"nko\",sans-serif}.submenu-scss__twobit___3MTTt{font-family:\"ubuntu\",sans-serif}.submenu-scss__hands___3Eq1M{font-family:\"comic\",sans-serif}.submenu-scss__container___2jfFb{text-align:left;display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:start;justify-content:flex-start;position:fixed;left:0;width:100vw;max-height:90vh;z-index:50;transition-property:top;transition-duration:300ms;transition-timing-function:ease-in-out}.submenu-scss__itemswrapper___3iL0W{width:100vw;max-width:700px;max-height:90vh;overflow:scroll;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:start;justify-content:flex-start;-ms-flex-align:start;align-items:flex-start;background-color:#fff;color:#3c3c3c;padding:20px}.submenu-scss__logo___1onYU{width:30%;padding-bottom:20px}.submenu-scss__link___3EzGB{cursor:pointer;width:100%;padding:5px;color:#3c3c3c;font-weight:900;transition-property:color;transition-duration:300ms}.submenu-scss__shadow___1px52{position:fixed;top:0;left:0;width:100vw;height:100vh;z-index:40;background-color:rgba(0,0,0,.7);transition-property:opacity;transition-duration:300ms;transition-timing-function:ease-out;transition-delay:200ms;pointer-events:none}.submenu-scss__show___ZJwN5{top:0}.submenu-scss__hide___1lwJb{top:-1000px}.submenu-scss__in___1WBUV{opacity:1;pointer-events:auto}.submenu-scss__out___2JGlR{opacity:0;pointer-events:none}", ""]);

// Exports
exports.locals = {
	"snes": "submenu-scss__snes___2zZgw",
	"twobit": "submenu-scss__twobit___3MTTt",
	"hands": "submenu-scss__hands___3Eq1M",
	"container": "submenu-scss__container___2jfFb",
	"itemswrapper": "submenu-scss__itemswrapper___3iL0W",
	"logo": "submenu-scss__logo___1onYU",
	"link": "submenu-scss__link___3EzGB",
	"shadow": "submenu-scss__shadow___1px52",
	"show": "submenu-scss__show___ZJwN5",
	"hide": "submenu-scss__hide___1lwJb",
	"in": "submenu-scss__in___1WBUV",
	"out": "submenu-scss__out___2JGlR"
};
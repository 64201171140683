//@flow

import Dispatcher from '../../../Dispatcher';

const ActionTypes = {
  SET_SHOW_MENU: 'SET_SHOW_MENU'
};

const Actions = {
  setShowMenu(show: boolean) {
    Dispatcher.dispatch({
      type: ActionTypes.SET_SHOW_MENU,
      show: show
    });
  }
}

export {
  Actions,
  ActionTypes
};
//@flow

import {
  OrderedMap,
} from 'immutable/dist/immutable.min';

import {
  ReduceStore
} from 'flux/utils';

import Dispatcher from '../../../Dispatcher';

import {
  ActionTypes
} from './actions';

class Store extends ReduceStore < Object > {
  constructor() {
    super(Dispatcher);
  }

  getInitialState(): Object {
    return OrderedMap({
      showMenu: false
    });
  }

  reduce(state: Object, action: Object): Object {
    switch (action.type) {
      case ActionTypes.SET_SHOW_MENU:
        return state.set('showMenu', action.show);

      default:
        return state;
    }
  }
}

export default new Store();
//@flow
import * as React from 'react';

import {
  Container
} from 'flux/utils';
import Store from './signup-store';
import Signup from './signup'
import {
  Actions
} from './signup-actions';

type Props = {
  brandId: string,
  auth: string,
  responseBody ? : string,
  responseSubject ? : string,
  timeout: number,
  alwaysSilent: boolean,
  firstTitle: string,
  secondTitle: string,
  showNow: boolean
}

type State = {
  store: Object
}
class SignupContainer extends React.Component < Props, State > {

  static getStores() {
    return [Store];
  }

  static calculateState(prevState) {
    return {
      store: Store.getState(),
    };
  }

  componentWillMount() {

    setTimeout(() => {
      Actions.triggerM1()
    }, this.props.timeout);
    Actions.setBrandData(this.props.brandId, this.props.responseSubject, this.props.responseBody)
  }

  render() {
    // console.log('remder')
    // let showNow = (this.props.location.hash === "#signup") ? true : false;
    return <Signup {...this.props} store={this.state.store}/>
  }
}

export default Container.create(SignupContainer);
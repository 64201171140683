exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".cookie-accept__cookie-accept-container___YXqPC{z-index:9999999;position:fixed;right:0px;background-color:#ed1966;color:#fff;width:100vw;transition-property:bottom;transition-duration:300ms;transition-timing-function:ease-in;padding:8px;display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;bottom:0px;font-size:.8rem}.cookie-accept__cookie-accept-container___YXqPC a{text-decoration:underline;color:#fff}.cookie-accept__message___xTOUU{text-align:left;margin-right:4px;display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:start;justify-content:flex-start;-ms-flex-align:center;align-items:center;line-height:normal}.cookie-accept__buttons___O8U1I{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:end;justify-content:flex-end;-ms-flex-align:center;align-items:center}.cookie-accept__button___29Hz0{height:-moz-max-content;height:max-content;display:inline-block;text-transform:uppercase;padding:1px 8px;margin:0px 4px;border-radius:7px;border:1px solid #fff;cursor:pointer;transition-property:background-color;transition-duration:250ms}.cookie-accept__button___29Hz0:hover{background-color:#e85e90}@media(min-width: 767px){.cookie-accept__cookie-accept-container___YXqPC{width:auto}}.cookie-accept__show___UkicK{bottom:0px}.cookie-accept__hide___1RJpM{bottom:-80px}", ""]);

// Exports
exports.locals = {
	"cookie-accept-container": "cookie-accept__cookie-accept-container___YXqPC",
	"cookieAcceptContainer": "cookie-accept__cookie-accept-container___YXqPC",
	"message": "cookie-accept__message___xTOUU",
	"buttons": "cookie-accept__buttons___O8U1I",
	"button": "cookie-accept__button___29Hz0",
	"show": "cookie-accept__show___UkicK",
	"hide": "cookie-accept__hide___1RJpM"
};
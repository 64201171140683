exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".navbar-scss__main-menu___3u7Gq{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:center;justify-content:center;z-index:1000;font-weight:900}@media(max-width: 767px){.navbar-scss__main-menu___3u7Gq{-ms-flex-pack:end;justify-content:flex-end}}.navbar-scss__link___2e2pH{z-index:1000;color:#d0d0d0;display:inline-block;padding:6px 10px;margin:0;transition-property:color;transition-duration:300ms;cursor:pointer;text-align:center}.navbar-scss__link___2e2pH:hover{color:#d0d0d0}@media(min-width: 767px){.navbar-scss__link___2e2pH a:focus,.navbar-scss__link___2e2pH a:hover,.navbar-scss__link___2e2pH:focus,.navbar-scss__link___2e2pH:hover{color:#800e58;text-decoration:none}}.navbar-scss__selected___3l-rD{color:#003144}", ""]);

// Exports
exports.locals = {
	"main-menu": "navbar-scss__main-menu___3u7Gq",
	"mainMenu": "navbar-scss__main-menu___3u7Gq",
	"link": "navbar-scss__link___2e2pH",
	"selected": "navbar-scss__selected___3l-rD"
};
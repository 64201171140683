// @flow
import React from 'react';
// import ReactDOM from 'react-dom';
import FontAwesome from 'react-fontawesome';

const Loading = () => (
  <div style={{width: '100%', height: '100%',textAlign: 'center',padding:'50px'}}>
  <FontAwesome
      name='refresh'
      size='5x'
      spin
      style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0.1)' }}
    />
  </div>


)

export default Loading;

//@flow

import Dispatcher from '../../../Dispatcher';

import {
  Cookies
} from 'react-cookie';

import API from './interface';

const ActionTypes = {
  SUBMIT_M1: 'SUBMIT_M1',
  CANCEL_M: 'CANCEL_M',
  SUBMIT_M2: 'SUBMIT_M2',
  TRIGGER_M1: 'TRIGGER_M1',
  TRIGGER_M2: 'TRIGGER_M2',
  TRIGGER_M3: 'TRIGGER_M3',
  SET_DATA: 'SET_DATA',
  SET_BRAND_DATA: 'SET_BRAND_DATA',
  SET_API_STATUS: 'SET_API_STATUS',
  SET_CONFIRM_INTENT: "SET_CONFIRM_INTENT"
};

function freezeBodyForModal() {
  let body = document.getElementsByTagName("body");
  if (!body) return;
  let bodyClasses = body[0].classList;
  bodyClasses.add("signup-modal-open");
}

function unfreezeBodyForModal() {
  let body = document.getElementsByTagName("body");
  if (!body) return;
  let bodyClasses = body[0].classList;
  bodyClasses.remove("signup-modal-open");
}

const Actions = {
  triggerM1() {
    let cookies = new Cookies();
    if (!cookies.get('c87ndwjdj3edn3ued7')) {
      freezeBodyForModal();
      Dispatcher.dispatch({
        type: ActionTypes.TRIGGER_M1
      });
    }
  },
  triggerM2() {
    freezeBodyForModal();
    Dispatcher.dispatch({
      type: ActionTypes.TRIGGER_M2,
    });
  },
  triggerM3() {
    freezeBodyForModal();
    Dispatcher.dispatch({
      type: ActionTypes.TRIGGER_M3
    });
  },
  cancelM() {
    unfreezeBodyForModal()

    Dispatcher.dispatch({
      type: ActionTypes.CANCEL_M
    });


    let date = new Date();
    date.setTime(date.getTime() + (3 * 24 * 60 * 60 * 1000));
    let expires = date;

    let cookies = new Cookies();
    cookies.set('c87ndwjdj3edn3ued7', true, {
      path: '/',
      expires: expires
    });
  },
  submitM1() {
    API.post();
    Dispatcher.dispatch({
      type: ActionTypes.SUBMIT_M1
    });

  },
  submitM2() {
    API.post();

    Dispatcher.dispatch({
      type: ActionTypes.SUBMIT_M2
    });
  },
  setData(data: Object) {
    Dispatcher.dispatch({
      type: ActionTypes.SET_DATA,
      data: data
    });
  },
  setBrandData(brandId: string, responseSubject?: string, responseBody?: string) {
    Dispatcher.dispatch({
      type: ActionTypes.SET_BRAND_DATA,
      brandId: brandId,
      responseSubject: responseSubject,
      responseBody: responseBody
    });
  },
  setAPIStatus(s: string) {
    Dispatcher.dispatch({
      type: ActionTypes.SET_API_STATUS,
      status: s
    });
  },
  setConfirmIntent(b: bool) {
    Dispatcher.dispatch({
      type: ActionTypes.SET_CONFIRM_INTENT,
      data: b
    });
  }
}

export {
  Actions,
  ActionTypes
};
//@flow
import React from "react";
import Nav from "./src/navbar";
// import { BrowserRouter, Route, Link, Switch } from "react-router-dom";

import { Actions as SignupActions } from "lib/idd-signup";
import { Actions as NavActions } from "./src/actions";

const mainMenuItems = {
  Home: "/",
  Tickets: "/tickets",
  Lineup: "/lineup",
  // ReminisceTV: "/tv",
  // Photos: "/photos",
  // Info: '/info',
  FAQs: '/faq'
};
const subMenuItems = {
  "Signup for News & Offers": () => {
    NavActions.setShowMenu(false);
    SignupActions.triggerM2();
  },
  // 'Tickets direct to your door': '/mobileticketsellers',
  'How to get there': '/gettingthere',
  "Accessibility": "/disabledaccess",

  // "Our story": "/ourstory",

  "Terms & conditions": "/terms",
  "Data protection policy": "/data",
  "Contact Us": "/contact"
};

class NavController extends React.Component<*> {
  render() {
    return (
      <Nav
        mainMenuItems={mainMenuItems}
        subMenuItems={subMenuItems}
        {...this.props}
      />
    );
  }
}

export default NavController;

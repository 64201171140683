//@flow
import React, {
  Component
} from 'react';

// import {
//   Modal,
//   Button,
//   FormGroup,
//   FormControl,
//   HelpBlock,
//   ControlLabel
// } from 'react-bootstrap';

import Modal from 'react-bootstrap/es/Modal'
import Button from 'react-bootstrap/es/Button'
import FormGroup from 'react-bootstrap/es/FormGroup'
import Checkbox from 'react-bootstrap/es/Checkbox'
import FormControl from 'react-bootstrap/es/FormControl'
import HelpBlock from 'react-bootstrap/es/HelpBlock'
import ControlLabel from 'react-bootstrap/es/ControlLabel'
import css from './signup.scss.css';

import {
  Actions
} from './signup-actions'
import {
  validateEmail
} from './validate';

type Props = {
  title: string,
  show: boolean,
  store: Object
}



class Modal1 extends Component<Props> {

  formIsValidated = false;

  handleSubmit(e: SyntheticInputEvent<*>) {
    e.preventDefault();
    e.stopPropagation();
    if (this.formIsValidated) Actions.submitM1();
  }

  handleChange(o: Object) {
    let vals = {};
    Object.entries(o).forEach((e) => {
      switch (e[0]) {
        case 'email':
          vals['emailIsValidated'] = validateEmail(String(e[1]))
          break;
        default:
      }
    })
    Object.assign(o, vals)
    Actions.setData(o)
  }

  setFormIsValidated() {
    let vs = [];
    vs.push(this.props.store.get("emailIsValidated"));
    vs.push(this.props.store.get("confirmIntent"));
    if (vs.includes('error') || vs.includes('warning') || vs.includes(null) || vs.includes(undefined) || vs.includes(false)) {
      this.formIsValidated = false;
    } else {
      this.formIsValidated = true;
    }
  }

  getValidationState(key?: string): ?string {
    if (key) {
      let lookup = key + "IsValidated";
      let v = this.props.store.get(lookup);
      return v;
    } else {
      return this.formIsValidated ? "success" : null
    }
  }

  componentWillUpdate() {

  }
  render() {
    this.setFormIsValidated();
    return <div>
      <Modal show={this.props.show} className={css.signupModal}>
        <Modal.Header>
          <Modal.Title>{this.props.title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <form>
            <FormGroup
              controlId="email"
              validationState={this.getValidationState("email")}
              onSubmit={(e: SyntheticInputEvent<*>) => this.handleSubmit(e)}
            >
              <ControlLabel>Email:</ControlLabel>
              <FormControl
                name="email"
                type="text"
                value={this.props.store.get("email")}
                placeholder="email"
                onChange={(e: SyntheticInputEvent<*>) => this.handleChange({ email: e.target.value })}
              />
              <FormControl.Feedback />
              <HelpBlock></HelpBlock>
            </FormGroup>

            <FormGroup
              controlId="confirmIntent"
              validationState={this.props.store.get("confirmIntent") ? "success" : null}
              onSubmit={(e: SyntheticInputEvent<*>) => this.handleSubmit(e)}
            >

              <Checkbox
                name="confirmIntent"
                checked={this.props.store.get("confirmIntent")}
                onChange={(e: SyntheticInputEvent<*>) => this.handleChange({ confirmIntent: e.target.checked })}
              >I agree that Reminisce Festival may contact me by email with news and offers regarding the festival & related events.</Checkbox>


              <FormControl.Feedback />
            </FormGroup>


          </form>
          <div className={css.signupSmallprint}>We don't share your details with anyone else. You can remove yourself from the mailing list at any time. For more information about our data policies see <a href="/data">here.</a></div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={Actions.cancelM}>Close</Button>
          <Button type='submit' disabled={!this.formIsValidated} bsStyle="primary" onClick={(e: SyntheticInputEvent<*>) => this.handleSubmit(e)}>Submit</Button>
        </Modal.Footer>
      </Modal>
    </div>
  }
}

export default Modal1;
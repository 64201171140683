//@flow
import React, {
  Component
} from 'react';

// import {
//   Button
// } from 'react-bootstrap';
import Button from 'react-bootstrap/es/Button'


import {
  Actions
} from './signup-actions'

class SignupButton extends Component < * > {

  render() {
    return <Button bsStyle="danger" onClick={Actions.triggerM2}>Get the latest news and offers</Button>
  }
}
export default SignupButton;
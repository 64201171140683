//@flow
import React from "react";
import css from "./chatroom.scss.css";
import type { Message } from "./index";
import moment from "moment";

type Props = { message: Message, isBot: boolean, isMe: boolean, isAdmin: boolean };
type State = { opacity: number };

class MessageRow extends React.Component<Props, State> {
  state = { opacity: 0 };

  componentDidMount() {
    setTimeout(() => this.setState({ opacity: 1 }), 200);
  }
  componentDidUpdate() {}

  render() {
    const { message, isAdmin, isMe, isBot } = this.props;
    const cl = isBot ? css.messageFromBot : isAdmin ? css.messageFromAdmin : isMe ? css.messageFromMe : null;

    const ml = message.Body.length;
    const nl = message.Nickname.length;
    const l = ml > nl ? ml : nl;
    const w = l < 30 ? "30%" : l < 90 ? "46%" : "95%";

    return (
      <div className={[css.message].join(" ")} style={{ opacity: this.state.opacity, width: w, maxWidth: w }}>
        <div className={css.time}>{moment.unix(message.Timestamp).format("HH:mm")}</div>
        <div className={[css.inner, cl].join(" ")}>
          <div className={css.nickname}>
            {/* <span className={css.time}>{moment.unix(message.Timestamp).format("HH:mm")}&nbsp;</span> */}
            {/* <ion-icon name="arrow-forward-circle-outline"></ion-icon>&nbsp;{message.Nickname}
             */}
            {message.Nickname}
            {isAdmin && " [ADMIN]"}
          </div>
          <div className={css.body}>{message.Body}</div>
        </div>
      </div>
    );
  }
}

export default MessageRow;

//@flow
import "whatwg-fetch";

// import { PrivateActions } from "./actions";
// import Store from "./store";
// import { Actions as GlobalActions } from "../../services/actions";

// import { PrivateActions as Actions } from "./actions";
// import Hls from "hls.js";

// import { AudioSource } from "./source";
import { v4 as uuidv4 } from "uuid";

//private
let sessionID = "";
let sessionSrc = "";
let clientID = "";
let timerID: TimeoutID;
const app_id = "remweb";
// let sessionRunning = false;
//

function refreshSessionID(): string {
  sessionID = uuidv4();
  return sessionID;
}
function getSessionID(): string {
  return sessionID;
}
function getClientID(): string {
  if (clientID) return clientID;
  clientID = localStorage.getItem("@clientid");
  if (clientID) return clientID;
  clientID = uuidv4();
  localStorage.setItem("@clientid", clientID);
  return clientID;
}

function sendReport(vars: Object) {
  fetch("https://client-events-logger.service.indemand.digital", {
    method: "post",
    body: JSON.stringify(vars),
  }).catch((error) => {
    console.error("There has been a problem with your fetch operation:", error);
  });
}

export async function startSession(src: string) {
  if (src !== sessionSrc) {
    await endSession();
    refreshSessionID();
  }
  sessionSrc = src;
  const vars = {
    app_id: app_id,
    client_id: getClientID(),
    session_id: getSessionID(),
    src: src,
    event: "SESSION_ACTIVE",
  };
  // console.log(vars);
  sendReport(vars);
  timerID = setTimeout(() => startSession(src), 5000);
}

export function endSession() {
  clearTimeout(timerID);

  const vars = {
    app_id: app_id,
    src: sessionSrc,
    client_id: getClientID(),
    session_id: getSessionID(),
    event: "SESSION_ENDED",
  };
  // console.log(vars);
  sendReport(vars);
  sessionSrc = "";
}

function closingCode(){
  endSession();
  return null;
}
window.onbeforeunload = closingCode;
window.onunload = closingCode;
window.onclose = closingCode;